<template>
  <v-row>
    <v-col cols="8">
      <v-card class="pt-5">
        <v-card-title class="d-flex align-center justify-space-between">
          <span>{{ t(merchant.name) }} ({{ merchant.code }})</span>
          <div>
            <v-chip
              small
              class="v-chip-light-bg text-sm font-weight-semibold ml-2 text-capitalize"
              :class="{ 'success--text': merchant.active }"
            >
              {{ merchant.active ? 'Active' : 'Inactive' }}
            </v-chip>
          </div>
        </v-card-title>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          Introduction
        </v-card-title>
        <v-card-text>
          <div
            class="mb-4 html-content"
            v-html="t(merchant.introduction)"
          >
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="4">
      <v-card class="mt-2">
        <v-card-title class="justify-center flex-column">
          Datetimes
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <span>Joined At: </span>
            <span v-if="merchant.joined_at">{{ merchant.joined_at | date }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span>Created At: </span>
            {{ merchant.created_at | date }}
          </div>
          <div class="d-flex justify-space-between">
            <span>Updated At: </span>
            {{ merchant.updated_at | date }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { t } from '@/plugins/i18n'
import { date, price } from '@core/utils/filter'
import { mdiComment, mdiCurrencyUsd, mdiEye, mdiEyeRemove, mdiStar } from '@mdi/js'

export default {
  filters: { price, date },

  props: {
    merchant: { type: Object, required: true },
  },

  setup() {
    return {
      t,

      icons: {
        mdiStar,
        mdiComment,
        mdiCurrencyUsd,
        mdiEye,
        mdiEyeRemove,
      },
    }
  },
}
</script>

<style lang="scss">
.html-content {
  img {
    max-width: 100%;
  }
}
</style>
