<template>
  <v-row>
    <shipping-rule-aside
      :active="aside"
      :merchant="merchant"
      :resource="merchant.shipping_rule"
      @changed="$emit('updated')"
    >
    </shipping-rule-aside>

    <v-col cols="8">
      <v-card class="pt-5">
        <v-card-title class="d-flex align-center justify-space-between">
          <span>Shipping Fee</span>
        </v-card-title>

        <v-card-text>
          <p>This fee applies to all tangible orders.</p>
          <p>You may ignore this section if you don't intent to provide tangible products.</p>
          <v-divider />

          <div class="mt-2">
            <span>Shipping Fee:</span>
            <b>
              {{ merchant.shipping_rule.shipping_fee | price }}
            </b>
          </div>

          <div class="mt-2">
            <span>Free Shipping if min order total is:</span>
            <b>{{ merchant.shipping_rule.waived_at_subtotal | price }}</b>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="aside = true"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="8">
      <v-card class="pt-5">
        <v-card-title class="d-flex align-center justify-space-between">
          <span>Shipping Methods</span>
        </v-card-title>

        <v-card-text>
          <sync-shipping-method-form
            v-if="merchant && ready"
            :selected-ids="shippingMethods.map(c => c.id)"
            :sync-base-path="`/merchant/current/shipping-methods/sync`"
            :sync-able="syncAble"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="confirmSync"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { fetchShippingMethods } from '@/api/merchant/merchant'
import { notifySuccess } from '@/assets/js/utils'
import SyncShippingMethodForm from '@/modules/merchant/components/shipping-method/SyncShippingMethodForm.vue'
import ShippingRuleAside from '@/modules/merchant/components/shipping-rule/ShippingRuleAside.vue'
import { price } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

export default {
  components: { ShippingRuleAside, SyncShippingMethodForm },
  filters: { price },

  props: {
    merchant: { type: Object, required: true },
  },

  setup(props) {
    const aside = ref(false)
    const syncAble = ref(false)
    const ready = ref(false)
    const shippingMethods = ref([])

    fetchShippingMethods(props.merchant.id).then(res => {
      ready.value = false
      shippingMethods.value = res.data.data.records
      setTimeout(() => {
        ready.value = true
      })
    })

    const confirmSync = () => {
      syncAble.value = true
      notifySuccess({ content: 'Shipping Methods updated' })
      setTimeout(() => fetchShippingMethods(props.merchant.id), 300)
    }

    return {
      aside,
      syncAble,
      shippingMethods,
      ready,
      confirmSync,
    }
  },
}
</script>

<style lang="scss">
.html-content {
  img {
    max-width: 100%;
  }
}
</style>
